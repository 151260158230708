// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

const myCustomTheme = {
  dark: false,
  colors: {
    primary: "#0354e0",
    whiteColorVariant1: "#a6c6ff",
    greenColorVariant1: "#cefcce",
    blueColorVariant1: "#ccd7eb",
    blueColorVariant2: "#003a9f",
    blueColorVariant3: "#f0f8ff",
    blueColorVariant4: "#0354e0 ",
    redColorVariant1: "#FFE5E5",
  },
};

export default createVuetify({
  theme: {
    defaultTheme: "myCustomTheme",
    themes: {
      myCustomTheme,
    },
  },
});

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides

