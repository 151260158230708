import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
export default createStore({
  state: {
    EmailID: "",

    GetCurrentUser: {},
  },
  getters: {
    get_user_email: (state) => {
      return state.EmailID;
    },
    get_current_user_details: (state) => {
      return state.GetCurrentUser;
    },
  },
  mutations: {
    SET_USEREMAIL(state, EmailID) {
      state.EmailID = EmailID;
    },
    SET_CURRENTUSER_DETAILS(state, GetCurrentUserDetails) {
      state.GetCurrentUser = GetCurrentUserDetails;
    },
  },
  plugins: [createPersistedState()],
  actions: {},
  modules: {},
});

